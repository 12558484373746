import { graphql, Script } from "gatsby"
import React, { useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/hire-pages-compoents/Banner"
import Statics from "../components/hire-typescript/Statics"
import Empower from "../components/hire-pages-compoents/WhyShould"
import WhyHireAngular from "../components/hire-pages-compoents/WhyHireAngular"
import Maximize from "../components/hire-pages-compoents/Maximize"
import HowHireAngular from "../components/hire-python-developers/HowHireAngular"
import HireDevelopers from "../components/hire-pages-compoents/HireDevelopers"
import Excel from "../components/hire-pages-compoents/Engineering"
import AngularTechStacks from "../components/hire-full-stack-developers/AngularTechStacks"
import AngularTechStacksMobile from "../components/hire-full-stack-developers/AngularTechStacksMobile"
import AngularIndustries from "../components/hire-pages-compoents/AngularIndustries"
import QuickReads from "../components/hire-laravel-developers/QuickReads"
import Solutions from "../components/hire-pages-compoents/Solutions"
import SolutionsMobile from "../components/hire-pages-compoents/SolutionsMobile"
import Success from "../components/hire-pages-compoents/Success"
import Remote from "../components/hire-pages-compoents/Remote"
import Combination from "../components/hire-pages-compoents/FullStackCombination"
import Finest from "../components/hire-full-stack-developers/Finest"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const FullStack = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const heroSection = data?.strapiPage?.sections[0]
  const whyhire = data?.strapiPage?.sections[1]
  const excel = data?.strapiPage?.sections[2]
  const maximizeAngular = data?.strapiPage?.sections[3]
  const developers = data?.strapiPage.sections[4]
  const how = data?.strapiPage?.sections[5]
  const rubust = data?.strapiPage?.sections[6]
  const remote = data?.strapiPage?.sections[7]
  const best = data?.strapiPage?.sections[8]
  const TechStacksAngular = data?.strapiPage?.sections[9]
  const cta = data?.strapiPage?.sections[10]
  const industriesAngular = data?.strapiPage?.sections[11]
  const solutions = data?.strapiPage?.sections[12]
  const awesome = data?.strapiPage?.sections[13]
  const quick = data?.strapiPage?.sections[14]
  const faqs = data?.strapiPage?.sections[15]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  const devSDetails = [
    {
      name: "Sophia Gracia",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/sophia_dev_290729630d.png",
      experience: "8+ years of experience",
      desc: "With a proven ability to architect & implement complex systems aligning with industry best practices. Sophia is a pro in caching strategies & optimization techniques.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/5_Stars_f7c42777ee.svg",
      reviews: "<b>5.0</b> Reviews",
      skills: [
        "Java",
        "JavaScript",
        "Python",
        "React",
        "Node.js",
        "Prisma",
        "Sequelize",
        "RESTful APIs",
        "Socket.io",
        "TypeScript",
        "JavaScript",
        "Git",
        "Spring Boot",
        "GraphQL",
        "Kafka",
        "Vue Js",
        " Nuxt.js",
        "NextJs",
        "AWS S3",
        "AWS AppSync",
        "MySQL",
        "PostgreSQL",
        "Redis",
        "MongoDB",
        "Firebase",
        "Docker",
        "Kubernetes",
        "Microservices",
      ],
    },
    {
      name: "Stewart Jerry.",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stewart_dev_8c199d8e54.png",
      experience: "5+ years of experience",
      desc: "Onboard Stewart, with expertise in frontend & backend development. He is skilled in designing & implementing RESTful APIs & optimizing database performance.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/4_5_Stars_bcd03e6880.svg",
      reviews: "<b>4.8</b> Stars Reviews",
      skills: [
        "Git",
        "NextJs",
        " Nuxt 2.js",
        "NestJs",
        "RESTful APIs",
        "Laravel",
        "Lumen",
        "AWS",
        "TypeScript",
        "MySQL",
        "PostgreSQL",
        "MongoDB",
        "Prisma",
        "Sequelize",
        "ExpressJs",
        "GraphQL",
        "Docker",
        "Microservices",
      ],
    },
    {
      name: "Naoma Silva",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/naoma_dev_2b687de3ea.png",
      experience: "5+ Years Of Experience",
      desc: "Naoma prioritizes clean code & stays updated on emerging technologies. She can be a great asset with her innovative approach, punctuality & collaborative skills.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_5_c3048ffdfe.svg",
      reviews: "<b>4.6</b> Stars Reviews",
      skills: [
        "Android",
        "iOS",
        "Flutter",
        "Git",
        "Java",
        "Kotlin",
        "Swift",
        "Objective-c",
        "Firebase",
        "SwiftUI",
        "Dart",
        "GraphQL",
        "AWS",
        "Xcode",
      ],
    },
  ]

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        pageName="Hire Angular Developers"
        hireFullStack={true}
      />
      <Statics />
      <WhyHireAngular strapiData={whyhire} />
      <Excel strapiData={excel} fullExcel={true} />
      <Maximize
        strapiData={maximizeAngular}
        productmanager={true}
        drupalMaximize={true}
        hireLaravel={true}
      />
      <HireDevelopers strapiData={developers} devSDetails={devSDetails} />
      <HowHireAngular strapiData={how} />
      <Empower strapiData={rubust} Vueidentify={true} />
      <Remote strapiData={remote} fullStackId={true} />
      <Combination strapiData={best} />
      {!isMobile && !isTablet ? (
        <AngularTechStacks strapiData={TechStacksAngular} />
      ) : (
        <AngularTechStacksMobile strapiData={TechStacksAngular} />
      )}
      <Finest strapiData={cta} />
      <AngularIndustries strapiData={industriesAngular} fullIndsDec={true} />
      {!isMobile && !isTablet ? (
        <Solutions strapiData={solutions} />
      ) : (
        <SolutionsMobile strapiData={solutions} />
      )}
      <Success strapiData={awesome} />
      <QuickReads strapiData={quick} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query laravel {
    strapiPage(slug: { eq: "hire-full-stack-developers" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default FullStack
