// extracted by mini-css-extract-plugin
export var bannerBtnHowAngular = "HowHireAngular-module--bannerBtnHowAngular--3f52f";
export var card = "HowHireAngular-module--card--e1994";
export var cardAllData = "HowHireAngular-module--cardAllData--1d4ce";
export var cardContent = "HowHireAngular-module--cardContent--bd0c3";
export var cardWrapper = "HowHireAngular-module--cardWrapper--09473";
export var description = "HowHireAngular-module--description--ea29f";
export var getBtnAngular = "HowHireAngular-module--getBtnAngular--156a9";
export var headerContent = "HowHireAngular-module--headerContent--e3d2c";
export var heading = "HowHireAngular-module--heading--eafe9";
export var howHireAngular = "HowHireAngular-module--howHireAngular--c4906";
export var lottieImage = "HowHireAngular-module--lottieImage--0050d";
export var steps = "HowHireAngular-module--steps--c603e";