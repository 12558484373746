// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--821fc";
export var cardBg = "AngularTechStacksMobile-module--cardBg--e9ce8";
export var cards = "AngularTechStacksMobile-module--cards--521f0";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--084a1";
export var description = "AngularTechStacksMobile-module--description--1056b";
export var heading = "AngularTechStacksMobile-module--heading--2ae3c";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--ff32c";
export var nav = "AngularTechStacksMobile-module--nav--af334";
export var navItem = "AngularTechStacksMobile-module--nav-item--b13f1";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--8cc64";
export var tabData = "AngularTechStacksMobile-module--tabData--2fa3d";
export var tech = "AngularTechStacksMobile-module--tech--01e8a";
export var techIcon = "AngularTechStacksMobile-module--techIcon--aba0c";
export var techImg = "AngularTechStacksMobile-module--techImg--477ae";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--b7043";