// extracted by mini-css-extract-plugin
export var accImg = "Engineering-module--accImg--e7a47";
export var approachImg = "Engineering-module--approachImg--5f9b5";
export var btn_white1_border = "Engineering-module--btn_white1_border--6a13d";
export var description = "Engineering-module--description--e5455";
export var engineeringSQA = "Engineering-module--engineeringSQA--60f16";
export var frontendEngBg = "Engineering-module--frontendEngBg--a1b43";
export var head = "Engineering-module--head--d1bde";
export var heading = "Engineering-module--heading--65c6d";
export var keyBtn = "Engineering-module--keyBtn--c45d8";
export var nextGenRight = "Engineering-module--nextGenRight--f1ae9";
export var quesColor = "Engineering-module--quesColor--814d9";
export var quesColorSelected = "Engineering-module--quesColorSelected--ea9ec";
export var someColor = "Engineering-module--someColor--8dabd";
export var textColor = "Engineering-module--textColor--356bd";