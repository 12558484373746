import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import "./AngularTechStacksMobile.scss"
import * as styles from "./AngularTechStacksMobile.module.scss"

const AngularTechStacksMobile = ({ strapiData }) => {
  const data = [
    {
      title: "Frontend",
      tech: [
        {
          name: "HTML5",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html_c9276a9225.svg",
        },
        {
          name: "CSS3",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/css_7c8f0d73c9.svg",
        },
        {
          name: "JavaScript",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_3a530ef15c.svg",
        },
        {
          name: "Angular",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/angular_20d6810b14.svg",
        },
        {
          name: "React.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_ca6ed3bdfa.svg",
        },
        {
          name: "TypeScript",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/typescript_89b5021e2f.svg",
        },
        {
          name: "Vue Js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_c910c51b49.svg",
        },
        {
          name: "Swift",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/swift_33a98cab6c.svg",
        },
        {
          name: "Flutter",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flutter_0743e9d745.svg",
        },
        {
          name: "SASS",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sass_3be7fd6244.svg",
        },
        {
          name: "ELM",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/redis_832eeb3e2d.svg",
        },
        {
          name: "Gatsby",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gatsby_5a5e963c13.svg",
        },
        {
          name: "Ember.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ember_js_33648dc2bc.svg",
        },
        {
          name: "Svelte",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/svelte_58b8e77e46.svg",
        },
        {
          name: "Polymer",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/polymer_c4cad709ce.svg",
        },
        {
          name: "Aurelia",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aurelia_90d78e2983.svg",
        },
        {
          name: "Meteor.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/meteor_js_7c318d242c.svg",
        },
        {
          name: "Next.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/next_js_2abcc87df1.svg",
        },
        {
          name: "Semantic UI",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/semantic_ui_fbe2e26363.svg",
        },
      ],
    },
    {
      title: "Backend",
      tech: [
        {
          name: "Node Js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_6ac2a59695.svg",
        },
        {
          name: "Python",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_fa6e4ee957.svg",
        },
        {
          name: "Elixir",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/elixir_9a7c464187.svg",
        },
        {
          name: "PHP",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/php_748e7be408.svg",
        },
        {
          name: "GO",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/golang_d1242ceae6.svg",
        },
        {
          name: "Java",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_6aba03cf42.svg",
        },
        {
          name: "C#",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/c_sharp_22f7af8714.svg",
        },
        {
          name: "RUST",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/rust_30f3e2a7d1.svg",
        },
        {
          name: "TypeScript",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/typescript_89b5021e2f.svg",
        },
        {
          name: "Ruby",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ruby_6b6aa1217f.svg",
        },
      ],
    },
    {
      title: "Database",
      tech: [
        {
          name: "GraphQL",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/graphql_a175b84b25.svg",
        },
        {
          name: "Kafka",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kafka_90be31a743.svg",
        },
        {
          name: "Firebase",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/firebase_4c96467ff8.svg",
        },
        {
          name: "Redis",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/redis_832eeb3e2d.svg",
        },
        {
          name: "MongoDB",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_d52c993bb1.svg",
        },
        {
          name: "SQL",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sql_9c849237e4.svg",
        },
        {
          name: "Oracle",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/oracle_113b1c9811.svg",
        },
        {
          name: "PostgreSQL",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_8ae6f29185.svg",
        },
        {
          name: "MySQL",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_e3712a94db.svg",
        },
        {
          name: "CouchDB",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/couchdb_d3f755ed15.svg",
        },
        {
          name: "Indexed DB",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/indexed_db_bc13bf4b10.svg",
        },
        {
          name: "SQLite",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sqlite_52f9c8e82a.svg",
        },
        {
          name: "MS SQL Server",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ms_sql_server_aa9819c3a2.svg",
        },
        {
          name: "DynamoDB",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dynamodb_5eb959b40e.svg",
        },
      ],
    },
    {
      title: "Libraries",
      tech: [
        {
          name: "Socket.io",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/socket_dot_io_d72ff3e5a3.svg",
        },
        {
          name: "Tailwind CSS",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/tailwind_css_c12c4d4cbd.svg",
        },
        {
          name: "Bootstrap",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bootstrap_f1ea345f78.svg",
        },
        {
          name: "Redux",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/redux_5d70bf8020.svg",
        },
        {
          name: "JQuery",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jquery_57ef888daa.svg",
        },
        {
          name: "Gatsby",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gatsby_5a5e963c13.svg",
        },
        {
          name: "ThreeJS",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/three_js_5d484c4626.svg",
        },
        {
          name: "Prisma",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/prisma_09b25d7e78.svg",
        },
        {
          name: "Sequelize",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sequelize_c10fd8ce0e.svg",
        },
        {
          name: "GraphQL",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/graphql_a175b84b25.svg",
        },
        {
          name: "Flow.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Flow_js_54447a0dc2.svg",
        },
        {
          name: "Material UI",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/material_ui_6493060dc0.svg",
        },
        {
          name: "Core UI",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/core_ui_df8638d51c.svg",
        },
        {
          name: "Web GL",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/web_gl_8bf575cf05.svg",
        },
        {
          name: "Web 3",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/web_3_fd81f324a1.svg",
        },
        {
          name: "Mobx",
          icon: "https://invozone-backend.s3.amazonaws.com/mobx_7f39de7fb0.svg",
        },
        {
          name: "Intertia.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/intertia_js_4a4ca6be56.svg",
        },
        {
          name: "Ant Design",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ant_design_caaf678947.svg",
        },
        {
          name: "Emotion",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/emotion_ed9b35c31e.svg",
        },
        {
          name: "Styled Component",
          icon: "https://invozone-backend.s3.amazonaws.com/styled_component_dd33ec38ea.svg",
        },
        {
          name: "Lodash",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/lodash_e62a4773f1.svg",
        },
        {
          name: "D3.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/d3_js_ed00cdd651.svg",
        },
        {
          name: "Backbone.js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/backbone_js_58167158bd.svg",
        },
        {
          name: "Rxjs",
          icon: "https://invozone-backend.s3.amazonaws.com/rxjs_e5d2e1e7f8.svg",
        },
      ],
    },
    {
      title: "Frameworks",
      tech: [
        {
          name: "Django",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/django_fb695be35d.svg",
        },
        {
          name: "Flask",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flask_9d3eb65e98.svg",
        },
        {
          name: "Ruby on Rails",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ror_288e126ccd.svg",
        },
        {
          name: "Spring Boot",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/spring_boot_a9029a662a.svg",
        },
        {
          name: "ASP.NET",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/asp_dot_net_000af31af8.svg",
        },
        {
          name: "Phoenix",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/phoenix_e275cf4716.svg",
        },
        {
          name: "Gin",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gin_791afd6a61.svg",
        },
        {
          name: "Laravel",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/laravel_f5ec07af0a.svg",
        },
        {
          name: "Nest Js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nest_js_8c36819e7a.svg",
        },
        {
          name: "Express",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/express_js_cf33c0df42.svg",
        },
        {
          name: "Ktor",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ktor_b0ba2e5da8.svg",
        },
        {
          name: "Flutter",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flutter_0743e9d745.svg",
        },
        {
          name: "Next Js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/next_js_2abcc87df1.svg",
        },
        {
          name: "Nuxt Js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nuxt_js_3854110f8f.svg",
        },
        {
          name: "Electron Js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/electron_js_75166123c8.svg",
        },
        {
          name: "FastAPI",
          icon: "https://invozone-backend.s3.amazonaws.com/fastapi_c54992c5d8.svg",
        },
        {
          name: "Codeigniter",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/codeigniter_b8fc1b0ba1.svg",
        },
        {
          name: ".NET",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dot_net_core_95f5c7880e.svg",
        },
        {
          name: "Fastify",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/fastify_b5ec6537b0.svg",
        },
        {
          name: "Strapi",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/strapi_4370070911.svg",
        },
        {
          name: "Meteor Js",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/meteor_js_7c318d242c.svg",
        },
      ],
    },
    {
      title: "DevOps",
      tech: [
        {
          name: "AWS",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_56ceea49d3.svg",
        },
        {
          name: "Google Cloud",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/google_cloud_4528eb8baa.svg",
        },
        {
          name: "Azure",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_5e7cfc6e51.svg",
        },
        {
          name: "IBM Cloud",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ibm_cloud_d740fe43e9.svg",
        },
        {
          name: "Oracle Cloud",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/oracle_113b1c9811.svg",
        },
        {
          name: "Digital Ocean",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/digital_ocean_cba09cf805.svg",
        },
        {
          name: "Puppet",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/puppet_4eb3e1e512.svg",
        },
        {
          name: "Kubernetes",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kubernetes_4144b58ad0.svg",
        },
        {
          name: "Docker",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/docker_15f3d368e3.svg",
        },
        {
          name: "Jenkins",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jenkins_e867e458e6.svg",
        },
        {
          name: "Chef",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/chef_1889871b8e.svg",
        },
        {
          name: "Terraform",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/terraform_da8a549bfd.svg",
        },
      ],
    },
    {
      title: "AI & ML",
      tech: [
        {
          name: "TensorFlow",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/terraform_da8a549bfd.svg",
        },
        {
          name: "Keras",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/keras_78f8e4e2b9.svg",
        },
        {
          name: "Pytorch",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pytorch_ee2c466f66.svg",
        },
        {
          name: "Hugging Face",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/hugging_face_a05440a42e.svg",
        },
        {
          name: "NLTK",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nltk_78edfdde3a.svg",
        },
        {
          name: "OpenAI",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/open_ai_3a2b232f86.svg",
        },
        {
          name: "Spacy",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/spacy_dbdb7c5edc.svg",
        },
        {
          name: "Plotly",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/plotly_89b939597c.svg",
        },
        {
          name: "Matplotlib",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/matplotlib_373040e018.svg",
        },
        {
          name: "Pandas",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pandas_e1638710b9.svg",
        },
        {
          name: "OpenCV",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/opencv_a6963042d4.svg",
        },
        {
          name: "Numpy",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/numpy_f0743bedf5.svg",
        },
        {
          name: "SkLearn",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/scikit_learn_b5023fa93d.svg",
        },
      ],
    },
    {
      title: "Low Code & No Code",
      tech: [
        {
          name: "Bubble.io",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bubble_io_edf8220473.svg",
        },
        {
          name: "Builder.io",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/builder_io_df0bc54033.svg",
        },
        {
          name: "Zoho",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/zoho_b61895214f.svg",
        },
        {
          name: "Zapier",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/zapier_9bc40b55db.svg",
        },
        {
          name: "Webflow",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/webflow_aafbb68342.svg",
        },
        {
          name: "Shopify",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/shopify_eaf38f2b0b.svg",
        },
        {
          name: "WordPress",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/wordpress_63b068e298.svg",
        },
      ],
    },
  ]

  const [activeAccordion, setActiveAccordion] = useState(`${data[0]?.title}`)
  return (
    <div className="AngularTechMobile">
      <div className={styles.AngularIndusTechHireWeb}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Accordion defaultActiveKey={`${data[0]?.title}`}>
            {data &&
              data?.map((e, i) => (
                <Card className="" key={i}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={`${e?.title}`}
                    className={`AngularAccord ${
                      e?.title === activeAccordion ? "active-accordion" : ""
                    } ${
                      (i === 1 || i === 3 || i === 5 || i === 7) &&
                      e?.title !== activeAccordion
                        ? "second-accordion"
                        : (i === 0 || i === 2 || i === 4 || i === 6) &&
                          e?.title !== activeAccordion
                        ? "first-accordion"
                        : ""
                    }`}
                    onClick={() => setActiveAccordion(e?.title)}
                    style={
                      i === 1 && e?.title === activeAccordion
                        ? { marginTop: "24px" }
                        : {}
                    }
                  >
                    {e?.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${e?.title}`}>
                    <Card.Body className={`TypeScriptcardBody`}>
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
          </Accordion>
        </Container>
      </div>
    </div>
  )
}

export default AngularTechStacksMobile
