// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "AngularTechStacks-module--TechStacksWebHireAngular--5945e";
export var cardBg = "AngularTechStacks-module--cardBg--48971";
export var cards = "AngularTechStacks-module--cards--219bd";
export var cardsContentAngular = "AngularTechStacks-module--cardsContentAngular--a1f1c";
export var description = "AngularTechStacks-module--description--e9515";
export var heading = "AngularTechStacks-module--heading--a09eb";
export var iconssCard = "AngularTechStacks-module--iconssCard--ef86b";
export var nav = "AngularTechStacks-module--nav--7db5c";
export var navItem = "AngularTechStacks-module--nav-item--f1389";
export var navbarBlock = "AngularTechStacks-module--navbarBlock--c3c49";
export var tabData = "AngularTechStacks-module--tabData--3a4ba";
export var tech = "AngularTechStacks-module--tech--e1152";
export var techIcon = "AngularTechStacks-module--techIcon--d017b";
export var techImg = "AngularTechStacks-module--techImg--4526b";
export var technologyIcon = "AngularTechStacks-module--technologyIcon--57b77";